<template>
    <div class="task_block">
        <ul>
            <li>
                <p>{{ state.sentence.text }}</p>
            </li>
            <speech :state="state"></speech>
            <sa :state="state" :active="active"></sa>
            <mismatch :state="state"></mismatch>
        </ul>
    </div>
</template>

<script>
import Speech from './task/Speech';
import Sa from './task/Sa.vue';
import Mismatch from './task/Mismatch';
export default {
    props: ['state', 'active'],
    components: {
        Speech,
        Sa,
        Mismatch
    }
}
</script>
