<template>
    <div class="message_block error">
        <div class="box">
            <div class="left_box">
                <h3>違うフレーズに聞こえるようです</h3>
                <p>発語結果が課題英文と<br>一致するまで練習してみましょう。</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['state', 'active'],
}
</script>

