<template>
    <div class="message_block close">
        <div class="box">
            <p>音節ごとに発音を確認してみましょう</p>
            <button @click="close"><span>閉じる</span></button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['state', 'active'],
    methods: {
        close: function () {
            this.state.word = null;
            this.state.status = this.state.result;
        }
    }
}
</script>

