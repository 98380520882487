<template>
    <li class="mismatch" v-if="matched === false">
        <p>{{ state.stt }}</p>
    </li>
</template>

<script>
export default {
    props: ['state'],
    computed: {
        matched: function () {
            if (this.state.stt == null && this.state.sa == null) {
                return null;
            }

            return this.state.stt != null && this.state.sa != null;
        }
    }
}
</script>

