<template>
    <div class="table_block" v-if="state.word !== null">
        <table>
            <tr>
                <th class="blue_bg">音節</th>
                <th class="blue_bg">正しい音</th>
                <th class="green_bg">聞こえ方</th>
                <th class="green_bg">アドバイス</th>
                <th class="gray_bg">再生</th>
            </tr>
            <table-row v-for="(p, i) in state.word.phone_score_list"
                :state="state" :active="active" :phone="p" :index="i"
                :total="total" :key="i"></table-row>
        </table>
    </div>
</template>

<script>
import TableRow from './table/TableRow.vue';
export default {
    props: ['state', 'active'],
    components: {
        TableRow
    },
    computed: {
        total: function () {
            return this.state.word.phone_score_list.length;
        }
    }
}
</script>
