import Recorder from 'opus-recorder/dist/recorder.min';

export default class {
    constructor() {
        this.processor, this.destination = null;
        this.recording = false;
        
        this.recorder = new Recorder();

        this.recorder.onstart = () => {
            var context = this.recorder.audioContext;
            var mic = this.recorder.sourceNode;

            this.processor = context.createScriptProcessor(2048, 1, 1);
            this.destination = context.destination;

            mic.connect(this.processor);
            this.processor.connect(this.destination);

            this.recording = true;

            this.processor.onaudioprocess = e => {
                if (this.recording) {
                    var input = e.inputBuffer.getChannelData(0);
                    var length = input.length;
                    var max = 0;

                    for (var i = 0; i < length; i++) {
                        max = input[i] > max ? input[i] : max;
                        max = max > 1.0 ? 1.0 : max;
                    }

                    var ev = new CustomEvent('volumeUpdated', {
                        'detail': max
                    });
                    window.dispatchEvent(ev);
                }
            }

            var ev = new CustomEvent('recordingStarted');
            window.dispatchEvent(ev);
        };

        this.recorder.onstop = () => {
            if (this.processor !== null) {
                this.processor.disconnect(this.destination);
                this.processor = null;
            }

            this.recording = false;
        };

        this.recorder.ondataavailable = (buffer) => {
            var dataBlob = new Blob([buffer], {
                type: 'audio/ogg'
            });

            var ev = new CustomEvent('dataAvailable', {
                'detail': dataBlob
            });
            window.dispatchEvent(ev);
        };
    }

    start() {
        this.recorder.start();
    }

    stop() {
        this.recorder.stop();
    }
}


