<template>
    <div class="btn_block">
        <sentence-button :state="state" :active="active"></sentence-button>
        <record-button :state="state" :active="active"></record-button>
    </div>
</template>

<script>
import SentenceButton from './button/SentenceButton';
import RecordButton from './button/RecordButton';

export default {
    props: ['state', 'active'],
    components: {
        SentenceButton,
        RecordButton
    }
}
</script>
