<template>
    <button class="playback" v-on:click="onClick"
        :disabled="playing === false && this.active === false"
        :class="{on: playing}">
        <span></span>
    </button>
</template>

<script>
import {Howl, Howler} from 'howler';

var sound = null;

export default {
    props: ['state', 'active'],
    data: function () {
        return {
            playing: false,
        };
    },
    methods: {
        onClick() {
            if (this.playing) {
                sound.stop();
            } else {
                sound.play();
            }
        }
    },
    mounted: function () {
        var s = new Howl({
            src: [this.state.sentence.model_url]
        });

        s.on('play', () => {
            this.playing = true;
            this.state.status = 'playingSentence';
        });

        s.on('stop', () => {
            this.playing = false;
            this.state.status = this.state.result ? this.state.result : 'ready';
        });

        s.on('end', () => {
            this.playing = false;
            this.state.status = this.state.result ? this.state.result : 'ready';
        });

        sound = s;
    }
}
</script>
