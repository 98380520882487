<template>
    <component :is="currentComponent" :state="state" :active="active"></component>
</template>

<script>
import Bad from './message/Bad.vue';
import Empty from './message/Empty.vue';
import Good from './message/Good.vue';
import Mismatch from './message/Mismatch.vue';
import Normal from './message/Normal.vue';
import PlayingHistory from './message/PlayingHistory.vue';
import PlayingLatest from './message/PlayingLatest.vue';
import PlayingModel from './message/PlayingModel.vue';
import PlayingSentence from './message/PlayingSentence.vue';
import PlayingUser from './message/PlayingUser.vue';
import Processing from './message/Processing.vue';
import Ready from './message/Ready.vue';
import Recording from './message/Recording.vue';
import Table from './message/Table.vue';

var components = {
    'bad': Bad,
    'empty': Empty,
    'good': Good,
    'mismatch': Mismatch,
    'normal': Normal,
    'playingHistory': PlayingHistory,
    'playingLatest': PlayingLatest,
    'playingModel': PlayingModel,
    'playingSentence': PlayingSentence,
    'playingUser': PlayingUser,
    'processing': Processing,
    'ready': Ready,
    'recording': Recording,
    'table': Table,
};

export default {
    props: ['state', 'active'],
    computed: {
        currentComponent: function () {
            return components[this.state.status];
        }
    }
}
</script>

