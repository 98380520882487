import axios from 'axios';

var baseURL = location.host.indexOf('dev') === 0 ? 'https://dev.sastt.ifapi.net' : 'https://sastt.ifapi.net';

axios.defaults.baseURL = baseURL;
axios.defaults.headers['Accept'] = 'application/json';

export default class {
    constructor() {
        this.state = {
            axios: axios,

            status: null,
            result: null,

            message: '',
            messageType: 'normal',

            sentence: null,
            latest: null,
            histories: null,
            stt: null,
            sa: null,

            word: null,
        };

        // get sentence_id & token from query
        var search = window.location.search.slice(1).split('&');
        var query = {};

        for (let i = 0; i < search.length; i++) {
            let v = search[i].split('=');
            if (v.length == 2) {
                query[v[0]] = v[1];
            }
        }

        if (query.sentence_id && query.token) {
            this.state.axios.defaults.headers['Authorization'] = 'Bearer ' + query.token;

            // get Sentence
            this.state.axios.get('/api/' + query.sentence_id)
                .then((response) => {

                    this.state.sentence = response.data.sentence;
                    this.state.histories = response.data.histories;

                    this.state.status = 'ready';
                })
                .catch((error) => {
                    this.state.status = 'error';
                    console.log(error);
                });
        } else {
            console.log('missing sentence_id or token');
        }
    }
}