<template>
    <li class="pronunciation" v-if="matched === true">
        <p>
            <word v-for="(word, index) in state.sa.text_score.word_score_list"
                :state="state" :active="active" :key="index" :index="index" :word="word"></word>
        </p>
    </li>
</template>

<script>
import Word from './Word.vue';
export default {
    props: ['state', 'active'],
    components: {
        Word
    },
    computed: {
        matched: function () {
            if (this.state.stt == null && this.state.sa == null) {
                return null;
            }

            return this.state.stt != null && this.state.sa != null;
        }
    }
}
</script>
