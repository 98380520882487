<template>
    <div class="message_block result bad">
        <div class="box">
            <div class="icon"></div>
            <div class="right_box">
                <h3>音声一致率：<span>{{ state.sa.text_score.quality_score }}&#37;</span></h3>
                <p>よく発音できていません。繰り返し練習しましょう</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['state', 'active']
}
</script>

