<template>
    <tr>
        <td class="bold" :rowspan="phone.syllable.phone_count" v-if="phone.start">{{ phone.syllable.letters }}</td>
        <td :class="{red_text: result === false}">{{ phone.phone }}</td>
        <td class="left" :class="{red_text: result === false}" v-html="like"></td>
        <td class="left" v-html="result ? 'OK' : adv"></td>
        <td class="btn_area" :rowspan="total" v-if="index === 0">
            <div class="example">
                <button @click="onClickModel" :class="{on: playingModel}"
                    :disabled="playingModel === false && active === false">
                    <span></span>
                </button>
                <p>手本</p>
            </div>
            <div class="user">
                <button @click="onClickUser" :class="{on: playingUser}"
                    :disabled="playingUser === false && active === false">
                    <span></span>
                </button>
                <p>あなた</p>
            </div>
        </td>
    </tr>
</template>

<script>
import {Howl, Howler} from 'howler';
import PhoneTable from '../../PhoneTable.js';
var pt = new PhoneTable;

var model = null;
var user = null;

export default {
    props: ['state', 'active', 'phone', 'index', 'total'],
    data: function () {
        return {
            playingModel: false,
            playingUser: false
        }
    },
    computed: {
        result: function () {
            return this.phone.phone === this.phone.sound_most_like;
        },
        like: function () {
            return pt.get(this.phone.sound_most_like).like;
        },
        adv: function () {
            return pt.get(this.phone.phone).adv;
        },
        extent: function () {
            var start = 0;
            var end = 0;
            this.state.word.phone_score_list.forEach((v, i) => {
                if (i === 0) {
                    start = v.extent[0];
                }
                end = v.extent[1];
            });

            return {
                start: start,
                end: end,
            }
        }
    },
    methods: {
        onClickModel: function () {
            if (this.playingModel) {
                model.stop();
            } else {
                this.state.axios.post('/api/audio', {
                    word: this.state.word.word
                })
                    .then((response) => {
                        var s = new Howl({
                            src: [response.data.audio_url]
                        });

                        s.on('play', () => {
                            this.playingModel = true;
                            this.state.status = 'playingModel';
                        });

                        s.on('stop', () => {
                            this.playingModel = false;
                            this.state.status = 'table';
                        });

                        s.on('end', () => {
                            this.playingModel = false;
                            this.state.status = 'table';
                        });

                        model = s;
                        model.play();
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        onClickUser: function () {
            if (this.playingUser) {
                user.stop();
            } else {
                var s = new Howl({
                    src: [this.state.latest.audio_url],
                    sprite: {
                        default: [this.extent.start * 10, this.extent.end * 10 - this.extent.start * 10 + 30]
                    }
                });

                s.on('play', () => {
                    this.playingUser = true;
                    this.state.status = 'playingUser';
                });

                s.on('stop', () => {
                    this.playingUser = false;
                    this.state.status = 'table';
                });

                s.on('end', () => {
                    this.playingUser = false;
                    this.state.status = 'table';
                });

                user = s;
                user.play('default');
            }
        }
    }
}
</script>

