<template>
    <button class="diagnosis" v-on:click="record" :disabled="recording === false && active === false"
        :class="{on: recording}"
        :style="shadow">
        <span></span>
    </button>
</template>

<script>
import Recorder from '../../Recorder.js';
import _ from 'lodash';

function parseSa(sa) {
    if (sa === null) {
        return null;
    }

    sa.text_score.word_score_list.forEach((v, i) => {
        var phones = JSON.parse(JSON.stringify(v.phone_score_list));

        var replacePhones = [];

        v.syllable_score_list.forEach((v2, i2) => {
            v2.phones = phones.splice(0, v2.phone_count);

            v2.phones.forEach((v3, i3) => {
                v3.start = i3 === 0;
                v3.syllable = v2;
                v2.phones[i3] = v3;
            });

            replacePhones = _.concat(replacePhones, v2.phones);

            v.syllable_score_list[i2] = v2;
        });

        v.phone_score_list = replacePhones;

        sa.text_score.word_score_list[i] = v;
    });

    return sa;
}

export default {
    props: ['state', 'active'],
    data: function () {
        return {
            recorder: {},
            recording: false,
            timerId: null,
            inputLevel: 0,
        };
    },
    methods: {
        record: function () {
            if (this.active) {
                this.recorder.start();
                this.recording = true;

                if (this.timerId !== null) {
                    clearTimeout(this.timerId);
                    this.timerId = null;
                }

                this.timerId = setTimeout(() => {
                    this.stop();
                }, 1000 * 20);
            } else if (this.state.status === 'recording') {
                this.stop();
            }
        },
        stop: function () {
            this.recorder.stop();
            this.recording = false;
            this.inputLevel = 0;

            if (this.timerId) {
                clearTimeout(this.timerId);
                this.timerId = null;
            }
        },
        score: function (blob) {
            var params = new FormData;
            params.append('audio', blob);

            this.state.axios.post('/api/' + this.state.sentence.id, params)
                .then(response => {
                    console.log(response.data.sa);
                    
                    this.state.latest = response.data.latest;
                    this.state.histories = response.data.histories;
                    this.state.stt = response.data.stt;
                    this.state.sa = parseSa(response.data.sa);

                    if (this.state.stt !== null && this.state.sa !== null) {
                        if (this.state.sa.text_score.quality_score >= 85) {
                            this.state.result = this.state.status = 'good';
                        } else if (this.state.sa.text_score.quality_score >= 70) {
                            this.state.result = this.state.status = 'normal';
                        } else {
                            this.state.result = this.state.status = 'bad';
                        }
                    } else if (this.state.stt !== null && this.state.sa === null) {
                        this.state.status = 'mismatch';
                    } else if (this.state.stt === null && this.state.sa === null) {
                        this.state.status = 'empty';
                    } else {
                        this.state.status = 'ready';
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.state.status = 'error';
                });
        }
    },
    computed: {
        shadow: function () {
            var level;

            if (this.recording) {
                level = this.inputLevel;
            } else {
                level = 0;
            }

            var w = Math.round(level * 20) + 'px';
            return {'box-shadow': '0 0 0 ' + w + ' rgba(214,131,111,0.40)'};
        }
    },
    mounted: function () {
        this.recorder = new Recorder();

        // register volume updated event listener
        window.addEventListener('volumeUpdated', e => {
            this.inputLevel = e.detail;
        });

        window.addEventListener('recordingStarted', e => {
            this.state.status = 'recording';
            this.state.word = null;
        });

        window.addEventListener('dataAvailable', e => {
            this.state.status = 'processing';
            this.score(e.detail);
        });
    }
}
</script>

