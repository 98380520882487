import Vue from 'vue/dist/vue.esm';
import Store from './Store.js';

import TaskBlock from './components/TaskBlock.vue';
import ButtonBlock from './components/ButtonBlock.vue';
import MessageBlock from './components/MessageBlock.vue';
import ScoreBlock from './components/ScoreBlock.vue';
import TableBlock from './components/TableBlock.vue';

var store = new Store();

const app = new Vue({
    el: '#wrap',
    components: {
        TaskBlock,
        ButtonBlock,
        MessageBlock,
        ScoreBlock,
        TableBlock,
    },
    data: {
        state: store.state
    },
    computed: {
        initialized: function () {
            return this.state.status !== null;
        },
        active: function () {
            switch (this.state.status) {
                case 'ready':
                case 'mismatch':
                case 'empty':
                case 'good':
                case 'normal':
                case 'bad':
                case 'table':
                case 'error':
                    return true;
                    break;
                default:
                    return false;
            }
        }
    },
    updated: function () {
        console.log(this.state.status);
    }
});