<template>
    <span><a href="#" v-if="wrong" @click="onClick">{{ word.word }}</a><span v-if="wrong === false">{{ word.word }}</span>&nbsp;</span>
</template>

<script>
export default {
    props: ['state', 'active', 'word', 'index'],
    computed: {
        wrong: function () {
            var result = false;

            this.word.phone_score_list.forEach((p, i) => {
                if (p.phone !== p.sound_most_like) {
                    result = true;
                }
            });

            return result;
        }
    },
    methods: {
        onClick: function () {
            if (this.active) {
                this.state.word = this.word;
                this.state.status = 'table';
            }
            
        }
    }
}
</script>
