<template>
    <li class="last_time" :class="listClass">
        <div>
            <span :style="{width: width + '%'}">
                <span>{{ score.score }}%</span>
            </span>
        </div>
        <button @click="onClick" :class="{on: playing}" :disabled="isDisabled"><span></span></button>
    </li>
</template>

<script>
import {Howl, Howler} from 'howler';

var sound = null;

export default {
    props: ['state', 'active', 'score', 'index'],
    data: function () {
        return {
            playing: false
        }
    },
    computed: {
        listClass: function () {
            return 'round0' + (this.index + 1);
        },
        width: function () {
            return Math.floor(this.score.score * 0.7 + 30);
        },
        isDisabled: function () {
            return this.playing === false && this.active === false;
        }
    },
    methods: {
        onClick: function () {
            if (this.playing) {
                sound.stop();
            } else {
                var s = new Howl({
                    src: [this.score.audio_url]
                });

                s.on('play', () => {
                    this.playing = true;
                    this.state.status = 'playingHistory';
                });

                s.on('stop', () => {
                    this.playing = false;
                    this.state.status = this.state.result ? this.state.result : 'ready';
                });

                s.on('end', () => {
                    this.playing = false;
                    this.state.status = this.state.result ? this.state.result : 'ready';
                });

                sound = s;
                sound.play();
            }
        },
    }
}
</script>

