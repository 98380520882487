<template>
    <div class="score" v-if="state.word === null">
        <h2>スコア</h2>
        <ul>
            <latest v-if="state.latest !== null"
                :state="state" :score="state.latest" :active="active"></latest>
            <list v-for="(score, index) in state.histories"
                :state="state" :score="score" :active="active" :index="index" :key="index"></list>
        </ul>
    </div>
</template>

<script>
import Latest from './score/Latest.vue';
import List from './score/List.vue';
export default {
    props: ['state', 'active'],
    components: {
        Latest,
        List
    }
}
</script>

